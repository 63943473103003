import React from 'react';
import * as FlexWebChat from "@twilio/flex-webchat-ui";

class App extends React.Component {

  state = {};

  constructor(props) {
    super(props);

    const { configuration } = props;

    FlexWebChat.MainHeader.defaultProps.imageUrl = "https://www.kieffer-asociados.com.bo/onlinesgs/assets/kiefferw.png";
    FlexWebChat.MainHeader.defaultProps.titleText = 'Contáctanos';
    FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;

    FlexWebChat.MessagingCanvas.defaultProps.memberDisplayOptions = {
      yourDefaultName: 'Yo',
      theirDefaultName: 'Kieffer',
      yourFriendlyNameOverride: false,
      theirFriendlyNameOverride: false
    };

    FlexWebChat.MessageListItem.defaultProps.useFriendlyName = true;

    FlexWebChat.Manager.create(configuration)
      .then(manager => {

        manager.strings.WelcomeMessage = "Bienvenido al centro de asistencia de Kieffer & Asociados";
        manager.strings.PredefinedChatMessageAuthorName = "Kieffer";
        manager.strings.PredefinedChatMessageBody = "Hola! Cómo podemos ayudarte?";
        manager.strings.MessageCanvasTrayContent = `
                <h6></h6>
                <p>Cuando desees, ponte en contacto con nosotros.</p>`;
        manager.strings.MessageCanvasTrayButton = "Volver a contactarme";
        manager.strings.EntryPointTagline = "Contáctanos!";
        manager.strings.InputPlaceHolder = "Cómo podemos ayudarte?";

        this.setState({ manager });

        FlexWebChat.MessagingCanvas.autoInitChannel = true;
        this.setAfterStartEngagement(manager);
      })
      .catch(error => this.setState({ error }));
  }

  setAfterStartEngagement(manager){
    // FlexWebChat.Actions.invokeAction("ToggleChatVisibility", (payload) => {
    //   console.log(payload);
    // });
    FlexWebChat.Actions.addListener("beforeStartEngagement", (payload) => {
      console.log(payload.formData);
    });
    FlexWebChat.Actions.addListener("afterStartEngagement", (payload) => {
      console.log(payload);
      if (!payload.formData || !payload.formData.helptype) return;

      var question = payload.formData.helptype;
      const { channelSid } = manager.store.getState().flex.session;
      if (!question) return;
      console.log('SENDING INFO', payload.formData);
      manager.chatClient.getChannelBySid(channelSid).then(channel => {
          channel.sendMessage('Solicitud de '+question, payload.formData);
      });
    });
  }

  render() {
    const { manager, error } = this.state;
    if (manager) {
      return (
        <FlexWebChat.ContextProvider manager={manager}>
          <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
      );
    }

    if (error) {
      console.error("Failed to initialize Flex Web Chat", error);
    }

    return null;
  }
}

export default App;
